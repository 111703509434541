import React, { useState, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InfoScreen from './components/InfoScreen';
import QuestionScreen from './components/QuestionScreen';
import SummaryScreen from './components/SummaryScreen';
import Thankyou from './components/Thankyou';

export const SurveyContext = createContext();

const App = ({children}) => {
  const [score, setScore] = useState(0);
  const [finalanswers, setfinalAnswers] = useState([]);
  

  return (
    <Router>
      <SurveyContext.Provider value={{ score, setScore, finalanswers, setfinalAnswers }}> 
        <Routes>
          <Route path="/" element={<InfoScreen />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/questions" element={<QuestionScreen score={score} setScore={setScore} finalanswers={finalanswers} setfinalAnswers={setfinalAnswers} />} />
          <Route path="/summary" element={<SummaryScreen score={score} setScore={setScore} finalanswers={finalanswers} setfinalAnswers={setfinalAnswers} />} />
        </Routes>
      </SurveyContext.Provider> 
    </Router>
  );
}

export default App;