import styled from '@emotion/styled';
import 'react-toastify/dist/ReactToastify.css';

// Media queries
const breakpoints = {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
  };
  
  export const mediaQueries = {
    mobile: `@media (max-width: ${breakpoints.tablet})`,
    tablet: `@media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop})`,
    desktop: `@media (min-width: ${breakpoints.desktop})`,
  };

export const Container = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 30px auto;
  padding: 0 20px;
  // background-color: #f5f5f5;
  border-radius: 8px;
  white-space: pre-line;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);  // Dodaj cienie
  font-family: "Solzl", Sans-serif;
  height: 770px;
  position: relative;
  color: #012c58;
  `;

export const TextUnderline = styled.p`
text-decoration:underline;
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  white-space: pre-line;
`;

export const TextSmall = styled.p`
  color: #555;
  font-size: 10px;
  line-height: 1.6;
  margin-bottom: 20px;
  white-space: pre-line;
`;

export const TextSmallReg = styled.p`
  color: #555;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
  white-space: pre-line;
`;

export const Footer = styled.div`
max-width: 800px;
left: 0px;
bottom: 0px;
position: absolute;
`;

export const TextQ = styled.div`

  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  white-space: pre-line;
  height: 115px;
`;


export const Title = styled.h1`

  font-size: 32px;
  margin-bottom: 30px;
  height:115px;
`;

export const SubTitle = styled.h1`

  font-size: 32px;
  margin-bottom: 30px;
`;

export const Text = styled.p`

  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  white-space: pre-line;
`;

export const Button = styled.button`
  background: linear-gradient(to right, #4fc3f7, #0277bd);  // Dodaj gradient
  border: none;
  color: white;
  padding: 14px 28px;
  margin: 10px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);  // Dodaj cienie

  &:hover {
    background: linear-gradient(to right, #0277bd, #4fc3f7);  // Zmień gradient
  }
`;


export const ButtonEmail = styled.button`
  background: linear-gradient(to right, #4fc3f7, #0277bd);  // Dodaj gradient
  border: none;
  color: white;
  padding: 14px 118px;
  margin: 10px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);  // Dodaj cienie

  &:hover {
    background: linear-gradient(to right, #0277bd, #4fc3f7);  // Zmień gradient
  }
`;

export const Input = styled.input`
  width: 36%;
  padding: 14px 0 14px 10px;
  margin: 0px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: #4fc3f7;
  }
`;

export const Label = styled.label`
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
`;

export const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const RangeLabel = styled.label`
  font-size: 1rem;
  color: #0033cc;
  text-align: center;
  flex: 1 1 auto;
`;


export const StyledUl = styled.ul`
  list-style-type: decimal;
  padding-left: 20px;
`;

export const StyledLi = styled.li`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.6;
  text-align:justify;
`;

export const ImageContainer = styled.div`
  display: inherit;
  justify-content: space-between;
`;

export const StyledImage = styled.img`
  margin: 10px;
  width: calc(100%);
`;

export const StyledRangeInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #ddd;
  outline: none;
  border-radius: 8px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #4fc3f7;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
margin-top: -8px;
    &:hover {
      background: #0277bd;
    }
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4fc3f7;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
      background: #0277bd;
    }
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 8px;
  }
`;




