import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Title,TextSmall, Text, Button, ImageContainer, StyledImage, SecondStyledImage, Footer} from './styles';
import logotypy from '../img/6c1fe26fa.png';
import ChangeVisibility from './ChangeVisibility';


const Thankyou = () => {
  return (
<Container  id="container">
<ChangeVisibility />
<Title><br/>System analizy sytuacji przedsiębiorstwa</Title>
<Text>Agile to metodyka zarządzania projektami, która polega na dzieleniu projektu na etapy i kładzie nacisk na ciągłą współpracę i doskonalenie. Zrozumienie idei Agile sprawia, że zespoły samodzielnie decydują, w jaki sposób najlepiej wykonywać swoją pracę, a ich członkowie posiadają wszelkie kompetencje i umiejętności niezbędne do ukończenia poszczególnych iteracji.
</Text><Text>
Dziękujemy za za skożystanie z Systemu analizy sytuacji przedsiębiorstwa.
</Text>
<Text>
Nasi eksperci skontaktują się z Tobą pod podanym numerem telefonu.
</Text>
<Footer>  
      <ImageContainer>
  <StyledImage src={logotypy} />
</ImageContainer>
<TextSmall>Zadanie publiczne pn. "Opracowanie strategii zmian transformacji cyfrowej w zakresie podejście do zarządzania - Agile”<br/> dofinansowane ze środków z budżetu Województwa Mazowieckiego. <br/><br/></TextSmall>
</Footer>
</Container>
  );
}

export default Thankyou;
