import React, { useState } from 'react';

const ChangeVisibility = () => {
const [isClassAdded, setIsClassAdded] = useState(false);

  const addClass = (className) => {
    const mainDiv = document.getElementById('main');
    mainDiv.classList.add(className);
    setIsClassAdded(true);
  };

  const removeClass = (className) => {
    const mainDiv = document.getElementById('main');
    mainDiv.classList.remove(className);
    setIsClassAdded(false);
  };

  const changeFontSize = (fontSize) => {
    const mainDiv = document.getElementById('main');
    if(fontSize != 'normal'){
        mainDiv.classList.remove('large');
        mainDiv.classList.remove('larger');
        mainDiv.classList.add(fontSize);
    }else {
        mainDiv.classList.remove('large');
        mainDiv.classList.remove('larger');
    }
     
    setIsClassAdded(false);
  };

  return (
    <div className='contrastButtons'>
      <div>  Kontrast: 
      <button className='no-contrast' onClick={() => removeClass('contrast')}  aria-label="standardowa wersja kontrastowa"><i class="icon-adjust-solid" aria-hidden="true" ></i></button>
      <button className='contrast-on' onClick={() => addClass('contrast')} aria-label="czarno-żółta wersja kontrastowa"><i class="icon-adjust-solid" aria-hidden="true"></i></button>
      </div>
      <div className='fontsbuttons'> Wielkość czcionki: <button onClick={() => changeFontSize('normal')} aria-label="standardowa czcionka">A+</button>
      <button onClick={() => changeFontSize('large')} aria-label="duża czcionka">A++</button>
      <button onClick={() => changeFontSize('larger')} aria-label="największa czcionka">A+++</button>
      </div>
    </div>
  );
};

export default ChangeVisibility;